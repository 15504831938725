<script setup lang="ts">
import dayjs from 'dayjs';

// Components
import GeneralGraph from './../components/graphs/GeneralGraph.vue';

// Composable
const router = useRouter();

// Data
const dateRange = ref({
  start: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
  end: dayjs().format('YYYY-MM-DD'),
});

// Methods
const setDate = (event: { start: Date; end: Date }) => {
  const start = dayjs(event.start).format('YYYY-MM-DD');
  const end = dayjs(event.end).format('YYYY-MM-DD');
  dateRange.value = { start, end };
  router.push({ query: { start, end } });
};

// Computed
const formatDateForDateSelector = computed(() => {
  return {
    start: dayjs(dateRange.value.start).toDate(),
    end: dayjs(dateRange.value.end).toDate(),
  };
});
</script>

<template>
  <div class="flex flex-col w-full h-full bg-gray-50">
    <ui-header :title="$t('global.consumption_overview')">
      <template #actions>
        <ui-range-date-selector :range="formatDateForDateSelector" @update:range="setDate">
          {{ $t('global.period') }}
        </ui-range-date-selector>
      </template>
    </ui-header>

    <div class="flex justify-start flex-col gap-4 p-4 overflow-y-auto">
      <general-graph :date-range="dateRange" />
    </div>
  </div>
</template>
